<template>
  <div id="package-info-id">
    <CardHeader ref="header" :node="headerTitle" />
    <div id="content-scroll" class="content-scroll" v-if="packageData != null">
      <div class="content">
        <div
          class="
          d-flex
          justify-content-between
          align-items-center
          content__item
          "
        >
          <div class="d-flex align-items-center">
            <h3 class="content__item__label f-w3">
              下記のプランが登録されています
            </h3>
          </div>
        </div>
        <div class="shadow mb-5 bg-white rounded card-main">
          <div class="card-body">
            <div class="title">
              <h5 class="card-title f-w3">登録済プラン</h5>
            </div>
            <div class="tag">
              <div
                class="fee"
                :class="packageData.number_month === 1 ? 'base' : 'hot'"
              >
                <div class="row">
                  <div class="col-12 cost">
                    <span>
                      <span class="cost-free f-segoe-ui-bold">{{
                        getPriceMonthPackage(
                          packageData.price,
                          packageData.number_month
                        )
                      }}</span>
                      <span class="currency-unit f-w3"> 円/月</span>
                    </span>
                    <span class="cost-right">
                      <span class="month f-w3"
                        >{{ packageData.number_month }}か月プラン
                      </span>
                      <span
                        class="cost-total f-w3"
                        v-if="packageData.number_month != 1"
                        >({{ getPricing(packageData.price) }}円)</span
                      >
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <hr class="line" />
            <div class="common-date">
              <div class="row">
                <div class="col-6 date-border">
                  <p class="date-title first-date expired-date f-w3">
                    有効期限
                  </p>
                </div>
                <div class="col-6">
                  <p class="date-title update-date f-w3">次回更新日</p>
                </div>
              </div>
              <div class="row">
                <div class="col-6 date-border">
                  <h4 class="date expired-date f-w6">
                    {{ fomatDate(packageData.end_date) }}
                  </h4>
                </div>
                <div class="col-6">
                  <h4 class="date update-date f-w6">
                    {{ fomatDate(packageData.end_date, 1) }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CardFooter ref="footer" />
  </div>
</template>

<script>
import CardHeader from "@/components/CardHeader";
import CardFooter from "@/components/CardFooter";
import "../../assets/scss/men/packageInfo.scss";
import { mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";

export default {
  name: "PackageInfo",
  components: {
    CardHeader,
    CardFooter
  },
  data() {
    return {
      headerTitle: {
        text: "契約の情報",
        isShowTitle: true,
        isShowLogo: false
      },
      packageData: null
    };
  },
  computed: {
    ...mapGetters({
      package: "auth/package"
    })
  },
  async created() {
    await this.getUsersPackage();
    this.packageData = this.package;
  },
  methods: {
    ...mapActions("auth", ["getUsersPackage"]),
    fomatDate(datetime, day_add = 0) {
      const date = dayjs(datetime).add(day_add, "day");
      const year = dayjs(date).format("YYYY"),
        month = dayjs(date).format("MM"),
        day = dayjs(date).format("DD");
      return year + "年" + month + "月" + day + "日 ";
    },
    getPricing(price) {
      return new Intl.NumberFormat("en-US").format(price);
    },
    getPriceMonthPackage(price, month) {
      return new Intl.NumberFormat("en-US").format(price / month);
    }
  }
};
</script>

<style scoped></style>
